.featured {
    margin: 0;
    margin-top: 25px;
    display: block;
    text-align: left;
}

.featured .media-wrapper {
    float: left;
    width: 43%;
    padding-right: 3vw;
}

.featured .media-wrapper .media-players {
   min-height: 400px;
}

.featured .collection-details-wrapper {
    padding: 0;
    width: 100%;
    color: white;
    text-align: left;
    width: 45%;
}

.featured .collection-details-wrapper .collection-title {
    font-size: 45px;
    font-weight: 800;
    line-height: 53px;
    margin-bottom: 16px;
}

.featured .collection-details-wrapper .collection-creators {
    font-size: 14px;
    font-weight: 800;
    line-height: 16px;
    margin-bottom: 32px;
}

.featured .collection-details-wrapper .collection-creators span {
    font-size: 12px;
    font-weight: normal;
}

.featured .collection-details-wrapper .collection-description {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    margin-bottom: 40px;
}

.featured .artists-details {
    float: left;
    padding:  0 0 0 0;
    width: 57%;
    color: white;
    text-align: left;
    margin-bottom: 50px;
}

.featured .artists-details .artist-avatar {
    float: left;
    margin-bottom: 15px;
    margin-right: 15px;
    width: 100px;
    height: 100px;
    background-size: cover;
    /* center the image vertically and horizontally */
    background-position: top center;
    /* round the edges to a circle with border radius 1/2 container size */
    border-radius: 50%;
}

.featured .artists-details .artist-details-wrapper {
    float: left;
    width: 84%;
    color: white;
    text-align: left;
}

.featured .artists-details .artist-details-wrapper .artist-details {
    margin-bottom: 12px;
}

.featured .artists-details .artist-details-wrapper .artist-name {
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
}







.featured .video-title-wrapper,
.featured .audio-title-wrapper
{
    margin: 16px 0;
}

.featured p.video-title,
.featured p.audio-title
{
    font-size: 18px;
    font-weight: 800;
    line-height: 21px;
    white-space: nowrap;
}

.featured .video-player {
    width: 100%;
    border-radius: 4px;
    padding-top: 0;
}

.featured .react-audio-player {
    width: 100%
}

.featured .mash-button-wrapper {
    float: left;
    width: 100%;
    text-align: center;
}

.featured .mash-button-wrapper .mash-button {
    width: 30%
}