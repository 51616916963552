.premint {
    /* display: flex;
    flex-direction: row; */
}

.premint .flow-wrapper {
    width: 100%;
    color: white;
    cursor: pointer;
}

.premint .row {
    display: inline-block;
    margin: 20px 0;
    width: 100%;
}

.premint .row .previewer {
    float: left;
    width: 16%;
    margin: 10px;
}

.premint .row .mashed-details {
    float: left;
    margin: 10px;
    width: auto;
    color: White;
}

.premint .row .field-label {
    font-size: 14px;
    font-weight: 600;
    color: White;
}

.premint .row .field-value {
    font-size: 24px;
    font-weight: 900;
    color: White;
}

.premint .row .video-details {
    float: left;
    margin: 10px;
    width: auto;
    color: White;
}

.premint .row .more-fields {
    float: left;
    width: auto;
    color: White;
    display: flex;
}

.premint .row .more-fields div {
    margin: 15px 20px 15px 0;
    width: auto;
}