.home {
    text-align: center;
}

.home .splash-wrapper {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
    margin-bottom: 30px;
    padding-bottom: 40px;
}

.home .splash-image {
    width: 100%;
}