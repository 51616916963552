@import './css/video-react.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  /* max-width: 70ch;
  padding: 3em 1em;
  margin: auto;
  line-height: 1.75;
  font-size: 1.25em; */
}

.home {
  height: auto;
  max-width: 100%;
}

.section {
  height: auto;
  /* background-color: gray; */
}

.section--statement {
  height: 350px;
  background-color: whitesmoke;
}

.section--dapp {
  height: 350px;
  background-color: lightgray;
}

.splash {
  /* center vertically and horizontally */
  margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.content {
  /* center vertically and horizontally */
  /* margin: 0;
  position: relative;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */
}

.mash {
  font-size: 90px;
  font-weight: bold;
  font-family: 'Wendy One', sans-serif;
}

.mash-sm {
  font-size: 15px;
  font-family: 'Wendy One', sans-serif;
}

.statement {
  font-size: 25px;
  font-weight: bold;
}

.howitworks {
  width: 100%;
}

.howitworks__td {
  width: 18%;
}

.howitworks__td span {
  margin-right: 10px;
}

.exo2 {
  font-family: 'Exo 2', sans-serif;
}

.section--splash {
  /* background-image: linear-gradient(45deg, #4f4f4f 25%, #4d4d4d 25%, #4d4d4d 50%, #4f4f4f 50%, #4f4f4f 75%, #4d4d4d 75%, #4d4d4d 100%);
  background-size: 56.57px 56.57px;  */
}

.section--musicart {
  background-color: whitesmoke;
  /* background-image: linear-gradient(45deg, #f7f7f7 25%, #fafafa 25%, #fafafa 50%, #f7f7f7 50%, #f7f7f7 75%, #fafafa 75%, #fafafa 100%);
  background-size: 113.14px 113.14px;   */
}

.section--whymash {
  background: rgb(238,245,241);
background: linear-gradient(90deg, rgba(238,245,241,0.9934567577030813) 53%, rgba(51,51,51,0.3772102591036415) 100%);
}

.section--interested {
  background-image: linear-gradient(45deg, #4f4f4f 25%, #4d4d4d 25%, #4d4d4d 50%, #4f4f4f 50%, #4f4f4f 75%, #4d4d4d 75%, #4d4d4d 100%);
  background-size: 56.57px 56.57px;
}
