.Mashboard {
    width: 100%;
    color: white;
}

.Mashboard .collection-details-wrapper {
    display: block;
    float: right;
}

.Mashboard .wrapper {
    display: inline-block;
    width: 100%;
}

.Mashboard .wrapper .preview-container {
    float: left;
    width: 30%;
}

.Mashboard .wrapper .videos-audios-container {
    float: left;
    width: 70%;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.Mashboard .videos-wrapper {
    width: auto;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}

.Mashboard .column-c-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
}

.Mashboard .mashboard-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.Mashboard .preview-pane {
    width: 30%;
    color: gray;
}

.Mashboard .flex-container {
    display: flex;
}

.Mashboard .flow-direction {
    position: relative;
    top: 10px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

/* Scroll;bar styles */

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #888;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #424242;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
