.Videos {
    display: flex;
    flex-direction: row;
    width: 100%;
}

/* override for play button in Videos */
.Videos .video-react-button {
    font-size: 1.1vw;
}

.Videos .videocard-wrapper {
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
}

/* when selected */
.Videos .videocard-wrapper.selectedvideo {
    border: 1px solid gray;
}
