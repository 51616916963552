.ColumnB {
    padding: 8px 20px 8px 0;
    width: auto;
}

.ColumnB .video-title-wrapper,
.ColumnB .audio-title-wrapper
{
    margin: 16px 0;
}

.ColumnB p.video-title,
.ColumnB p.audio-title
{
    font-size: 18px;
    font-weight: 800;
    line-height: 21px;
    white-space: nowrap;
}

.ColumnB .media-players {
    
}

.ColumnB .video-player {
    width: 100%;
    border-radius: 4px;
    padding-top: 0;
}

.ColumnB .react-audio-player {
    width: 100%
}

.ColumnB .mash-button-wrapper {
    width: 100%;
    text-align: center;
}

.ColumnB .mash-button-wrapper .mash-button {
    width: 30%
}