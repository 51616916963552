.audiocard-container {
    width: 211px;
    height: 205px;
    color: #FFFFFF;
    border-radius: 10px;
    overflow: hidden;
}

.audiocard-container .thumbnail-container {
    min-width: 211px;
    max-width: 211px;
    min-height: 90px;
}

.audiocard-container .thumbnail-container img {
    width: 211px;
    max-width: 211px;
    height: 90px;
}

.audiocard-container .divider {
    width: 100%;
    width: 89%;
    margin-left: 12px;
    border-color: #B388FF;
}

.audiocard-container .data-container {
    width: 211px;
    height: 100%;
    text-align: left;
    background-color: #424242;
    padding: 4px 4px 2px 12px;
}

.audiocard-container .data-container p {
    margin: 16px 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
}

.audiocard-container .title-container {
    overflow-y: hidden;
    white-space: nowrap;
}

.audiocard-container .title {
    font-size: 18px;
    font-weight: 800;
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
}

.audiocard-container .description-container {
    margin: 7px 0;
    width: 187px;
    height: 26px;
}

.audiocard-container .description-text {
    font-size: 12px;
    line-height: 14px;
}

.audiocard-container .tags-container a.tag {
    margin: 0 13px 0 0;
}