.collections {
    padding: 12px 0;
    background-color: #23272A;
    color: whitesmoke;
}

.collections .section-title {
    margin: 18px 18px 5px 0;
    font-size: 28px;
    font-weight: 800;
}

.collections .wrapper {
    padding: 0 0 20px 0;
    width: 100%;
}

.collections .flex-container {
    margin: 30px 0 0 0;
    display: flex;
    flex-wrap: wrap;
}

.collections .collection-card-wrapper {
    margin: 0 25px 30px 0;
    margin-bottom: 30px !important;
    display: inline;
    width: 200px;
    cursor: pointer;
    box-shadow: 5px 5px 5px black;
    -webkit-box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 20px 4px rgba(0,0,0,0.5);
}

.collections .collection-card-wrapper:hover {
    border: 1px solid gray;
    border-radius: 10px;
}

.collections .loader-spinner {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.collections .loader-spinner .comment {
    width: 100%;
    text-align: center;
    font-size: 8px;
}

.blinking {
    animation: mymove 1s infinite alternate;
}

@keyframes mymove {
    from {opacity: 0;}
    to {opacity: 1;}
}

