.Edit {
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
    color: white;
    padding: 6px;
}

.Edit .preview-container {
    width: auto;
}

.Edit .flow-wrapper {
    text-align: left;
}

.Edit .search-container {
    width: auto;
    display: flex; /* for ColumnA & ColumnB */
    flex-direction: row;
    height: 50px;
    border: 1px solid gray;
}

.Edit .column-ab-container {
    width: auto;
    display: flex; /* for ColumnA & ColumnB */
    flex-direction: column;
}

.Edit .videocard-wrapper {
    margin: 10px;
    width: 275px;
    border-radius: 10px;
    cursor: pointer;
}

.Edit .audiocard-wrapper {
    width: auto;
}

.Edit .mashboard-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.Edit .preview-pane {
    width: 30%;
    border: 1px dotted gray;
    color: gray;
}

.Edit .assets-pane {
    width: 68%;
    border: 1px dotted gray;
}

.Edit .flex-container {
    display: flex;
}


/* Scroll;bar styles */

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #888;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #424242;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
