.Nav {
  background: #23272a;
}

.Nav .logo {
  font-family: 'Gajraj One', serif;
  font-size: 20px;
  color: white;
}

.Nav ul {
  background: #23272a;
}

