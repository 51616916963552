.footer {
    margin: 0;
    display: block;
    clear: both;
    padding-top: 28px;
}

.footer .Nav {
    background: #23272a;
    text-align: left;
}

.footer .Nav .logo {
    font-family: 'Gajraj One', serif;
    font-size: 20px;
    color: white;
}

.footer .Nav ul {
    background: #23272a;
}


.footer .social-media-wrapper {
    float: right;
    padding: 0;
    margin: 0;
    margin-top: -30px;
}

.footer .social-media-wrapper .icon-discord {
    width: 30px;
    height: 20px;
    margin: 5px;
    display: inline;
}

.footer .social-media-wrapper .icon-twitter {
    width: 30px;
    height: 20px;
    margin: 5px;
    display: inline;
}

.footer .social-media-wrapper .icon-instagram {
    width: 30px;
    height: 20px;
    margin: 5px;
    display: inline;
}
