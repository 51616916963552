.collection-details-container {
    background-color: #23272A;
    text-align: right;
    padding: 15px 0 15px 15px;
}

.collection-details-container .fields {
    text-align: right;
}

.collection-details-container .fields span {
    font-size: 14px;
    font-weight: normal;
    margin: 0 3px;
}

.collection-details-container .fields span.collection-name {
    font-size: 30px;
    font-weight: bold;
}

.collection-details-container .fields span.royalties {
    font-weight: bold;
}

.collection-details-container .fields span.supply {
    font-weight: bold;
}

.collection-details-container .fields span.contract_address {
    font-weight: bold;
}
