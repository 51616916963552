.Audios {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.Audios .audio-card-container {
    display: flex;
    flex-direction: row;
}

.Audios .audiocard-wrapper {
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
}

/* when selected */
.Audios .audiocard-wrapper.selectedaudio {
    border: 1px solid gray;
}
